import { format, getTime, parse } from 'date-fns';
import {
  API_PATH_URL,
  CONTENT_LENGTH,
  CONTENT_MAX_LENGTH,
  MAX_HEIGHT,
  MAX_WIDTH,
  NWW_API_PATH_URL
} from '@/utils/constants';
import { useCloneDeep } from '#imports';
import { logger } from '~/lib/logger';

export const IS_BIDDING_AVAILABLE = false;

export const getFileExtension = (filename) => {
  if (filename && filename.length > 3) {
    return filename.split('.').pop();
  }
  return '';
};
export const getFirstLetter = (str) => {
  return str ? str.charAt(0).toUpperCase() : '';
};

export const capitalizeFirstLetterOfWords = (str, splitter = '_') => {
  if (!str) return '';
  return str
    .split(splitter)
    .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join(' ');
};

export const getFullName = (user) => {
  let firstName = '';
  let lastName = '';
  if (user.first_name) {
    firstName = user.first_name;
  }
  if (user.last_name) {
    lastName = user.last_name;
  }
  return `${firstName} ${lastName}`;
};

export const hexToRgbA = (hex) => {
  let c;
  try {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.2)';
    }
  } catch (_) {
    logger.warn(_);
  }
  return '';
};

export const getMimeType = (file, fallback = null) => {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = '';
  for (const element of byteArray) {
    header += element.toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const getFileToBase64 = (file) => {
  return new Promise((resolve, _reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
  });
};

export const trimContent = (content) => {
  if (content && content.length > CONTENT_MAX_LENGTH) {
    return `${content.substring(0, CONTENT_MAX_LENGTH - 3)}...`;
  }
  return content;
};

const allowedPaths = [
  '/realms',
  '/realms/create',
  '/realms/{id}',
  '/dashboard',
  '/profile',
  '/profile/edit',
  '/settings',
  '/',
  '/_auth/logout'
];
export const isDashboardOrRealm = (to) => {
  const path = to.fullPath.split('?')[0];
  const clonedPathData = useCloneDeep(allowedPaths);
  if (to.params.realmId) {
    clonedPathData[2] = clonedPathData[2].replace('{id}', to.params.realmId);
  }
  return clonedPathData.includes(path);
};

export const usFormatDate = (fromDate) => {
  if (fromDate) {
    return format(parse(fromDate, 'yyyy-MM-dd H:mm:ss', new Date()), 'MM/dd/yyyy');
  }
  return '-';
};

export const usFormatDateWithHourMinutes = (fromDate) => {
  if (fromDate) {
    return format(parse(fromDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 'MM/dd/yyyy HH:mm');
  }
  return '-';
};

export const certificateDateFormat = (fromDate, dateFormat = 'dd MMM yyyy') => {
  if (`${fromDate}`.includes('T')) {
    fromDate = fromDate.replace('T', ' ');
  }
  if (`${fromDate}`.includes('Z')) {
    fromDate = fromDate.replace('Z', '');
  }
  return format(parse(fromDate, 'yyyy-MM-dd H:mm:ss', new Date()), dateFormat);
};

export const fileModifiedDateTime = (fromTimestamp) => {
  if (fromTimestamp?.includes(':')) {
    fromTimestamp = getTime(parse(fromTimestamp, 'yyyy-MM-dd H:mm:ss', new Date()));
  }
  return format(fromTimestamp, 'dd MMM, yyyy ##  HH:mm').replace('##', 'at');
};

/**
 * getColorClass method used to get color class
 * @param {*} col
 * @param {*} row
 * @returns string
 */
export const getColorClassByColumn = (col, row) => {
  if (col?.columnColorClass && col?.className) {
    return `${col?.columnColorClass}  ${col?.className}`;
  } else if (col?.colorClasses) {
    return col?.colorClasses?.[row[col.key]];
  } else {
    return '';
  }
};

export const getNormalizeValue = (value) => value || '';

export const dangerBtnClasses = 'button--danger';
export const primaryBtnClasses = 'button--primary';
export const inputFieldClasses =
  'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2 focus:border-blue-500 focus:outline-none focus:shadow-outline-blue';
export const checkBoxClasses =
  'w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800';
export const radioButtonClasses =
  'w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800';

export const handleFloatedNumberString = (value) => {
  if (typeof value === 'string' && value.includes(',')) {
    value = value.replaceAll(',', '');
  }
  if (isNaN(parseFloat(value))) {
    return 0;
  }
  return parseFloat(value);
};

export const handleNumberString = (value, isFloor = false) => {
  value = handleFloatedNumberString(value);
  if (isFloor) {
    return Number(value);
  }
  return value;
};

export const handleNumber = (value) => {
  if (!value) {
    return '0';
  }
  const newValue = handleNumberString(value);
  return Number.parseFloat(newValue).toLocaleString('en-US');
};

export const handleProjectNumber = (value, toDecimal = 0) => {
  if (!value) {
    return '0';
  }
  const newValue = handleNumberString(value);
  const numberStingArray = `${newValue}`.split('.');
  let decimals = numberStingArray.length > 1 ? numberStingArray[1].length : 0;
  const localString = Number.parseFloat(newValue).toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
  if (toDecimal > 0) {
    const localStringArr = localString.toString().split('.');
    const decimalValueLength = localStringArr[1]?.length;
    if (decimalValueLength >= toDecimal) {
      return `${localStringArr[0]}.${localStringArr[1].slice(0, toDecimal)}`;
    } else {
      return localString;
    }
  }
  return localString;
  // return newValue; //Number.parseFloat(newValue).toLocaleString('en-US');
};

export const handleFloatNumber = (value, isReturn = false) => {
  const newValue = handleFloatedNumberString(value);
  if (!newValue) {
    return '0';
  }
  if (isReturn) {
    return newValue.toString();
  }
  return Number.parseFloat(newValue).toLocaleString('en-US');
};

export const fixNumber = (value, to) => Number(handleFloatNumber(value, true)).toFixed(to);

export const checkQuantity = (item) => {
  let calculatedEmission = Number(item.basketedQuantity * item.co2_emission.title).toFixed(2);
  if (parseFloat(item.basketedQuantity) > parseFloat(handleFloatedNumberString(item.total_available_qty.title))) {
    item.error = true;
  } else {
    delete item.error;
  }
  return calculatedEmission;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const handleNumberInput = (evt) => {
  evt = evt || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode === 46) {
    return true;
  }
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
  } else {
    return true;
  }
  return true
};

export const getQueryString = (queryObj) => {
  const query = [];
  for (const p in queryObj) {
    if (typeof queryObj[p] === 'object') {
      queryObj[p] = JSON.stringify(queryObj[p]);
    }
    query.push(encodeURIComponent(p) + '=' + encodeURIComponent(queryObj[p]));
  }
  return query;
};

export const assetFilterObj = () => {
  return {
    assetType: [],
    available: {
      minimum: '',
      maximum: '',
      includeVintage: false
    },
    posted: {
      minimum: '',
      maximum: '',
      includeVintage: false
    },
    creditedFrom: '',
    creditedTo: '',
    updatedFrom: '',
    updatedTo: ''
  };
};
export const transferFilterObj = () => {
  return {
    assetTypes: [],
    transferQtyMinimum: '',
    transferQtyMaximum: '',
    sellerBy: '',
    transferDateFrom: '',
    transferDateTo: '',
    requestedDateFrom: '',
    requestedDateTo: ''
  };
};

export const requestFilterObj = () => {
  return {
    assetTypes: [],
    requestedQtyMinimum: '',
    requestedQtyMaximum: '',
    counterPartyBy: '',
    transferTypeyBy: '',
    requestedDateFrom: '',
    requestedDateTo: ''
  };
};

export const allPostFilterObj = () => {
  return {
    assetTypes: [],
    postedQtyMinimum: '',
    postedQtyMaximum: '',
    postedDateFrom: '',
    postedDateTo: ''
  };
};
export const pendingTransferHistoryFilterObj = () => {
  return {
    assetTypes: [],
    availableQtyMinimum: '',
    availableMaximum: '',
    requestedQtyMinimum: '',
    requestedQtyMaximum: '',
    buyerBy: '',
    acceptedDateFrom: '',
    acceptedDateTo: ''
  };
};

export const myPostFilterObj = () => {
  return {
    assetTypes: [],
    pendingQtyMinimum: '',
    pendingQtyMaximum: '',
    postedQtyMinimum: '',
    postedQtyMaximum: '',
    postedDateFrom: '',
    postedDateTo: ''
  };
};

export const projectFilterObj = () => {
  return {
    projectStatus: [],
    fromDays: '',
    methodology: [],
    txtsearch: ''
  };
};

export const retiredFilterObj = () => {
  return {
    assetTypes: [],
    retiredQtyMinimum: '',
    retiredDateFrom: '',
    retiredDateTo: '',
    beneficiary: '',
    retiredBy: '',
    reinstatedBy: ''
  };
};

export const projectSortObj = () => {
  return {
    sortingColumn: '',
    sortingDirection: ''
  };
};

export const createApiPath = (path, apiFlag) => {
  let host;
  if (apiFlag) {
    host = `${window.location.protocol}//${window.location.host}/${NWW_API_PATH_URL}`;
  } else {
    host = `${window.location.protocol}//${window.location.host}/${API_PATH_URL}`;
  }
  return host.replace('{API_KEY}', path);
};

export const getDocTypeImage = (filename) => {
  let ext = getFileExtension(filename);
  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
      return 'image.svg';
    case 'xls':
    case 'xlsx':
      return 'xlsx-file.svg';
    case 'doc':
    case 'docx':
      return 'docx-file.svg';
    default:
      return 'pdf.svg';
  }
};

export const getFileSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const findAttrNode = (slug, currentNode, key = 'slug') => {
  let i, currentChild, result;
  if (slug === currentNode[key]) {
    return currentNode;
  } else {
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    if (currentNode?.children?.length) {
      for (i = 0; i < currentNode.children.length; i += 1) {
        currentChild = currentNode.children[i];

        // Search in the current child
        result = findAttrNode(slug, currentChild, key);

        // Return the result if the node has been found
        if (result !== false) {
          return result;
        }
      }
    }
    // The node has not been found and we have no more options
    return false;
  }
};

export const extractString = (str, providedLength = null) => {
  const newContentLength = providedLength || CONTENT_LENGTH;
  return str?.length > newContentLength ? `${str?.slice(0, newContentLength)}...` : str;
};

// To get the Height and Width of Upload Image
export const getExactImageRes = (file) => {
  return new Promise((_resolve, _reject) => {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      const image = new Image();
      image.onload = () => {
        const { width, height } = image;
        if (width <= MAX_WIDTH && height <= MAX_HEIGHT) {
          _resolve(true);
        } else {
          _reject(new Error(`Image size should be less than ${MAX_WIDTH} X ${MAX_HEIGHT}`));
        }
      };
      image.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const fixCo2E = (text) => {
  const co2E = `CO${String.fromCharCode(8320 + 2)}e`;
  return text?.replaceAll('CO2e', co2E);
};
