import { abilitiesPlugin } from '@casl/vue';
import useAuthStore from '@/stores/useAuthStore';
import { ability } from '@/services/ability';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore();
  nuxtApp.vueApp.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  });
  const permissions = authStore.getPermissions || [];
  ability.update(permissions);
  return {
    provide: {
      ability
    }
  };
});
