export const MAX_WIDTH = 100;
export const MAX_HEIGHT = 100;
export const SMS_TIME = 120;
export const PASSWORD_MAX_LENGTH = 6;
export const CONTENT_MAX_LENGTH = 45;
export const SUCCESS_ERROR_MESSAGE_TIME = 5 * 1000;
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const SETTING_MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

export const ERROR_STATUS = 422; // FOR ALL ERRORS

export const PROJECT_WISE_DECIMAL_PLACES = 5;

// //project status
export const PROJECT_PRE_SUBMISSION = 1;
export const PROJECT_SUBMITTED = 2;
export const PROJECT_CLAIMED_REVIEW = 3;
export const PROJECT_PENDING_EDITS_OR_AWAITING_UPDATES = 4;
export const PROJECT_RE_SUBMITTED = 5;
export const PROJECT_VERIFIED = 10;
export const CREDIT_POSTED = 11;

export const REALM_TYPE = {
  REALM_VERIFIER: 3,
  REALM_CREATOR: 1
};

export const PROJECT_STATUS = {
  CREATOR: [
    { id: 0, status: 'All', stClass: 'all' },
    {
      id: 1,
      status: 'Pre-Submission',
      stClass: 'pre-submission'
    },
    {
      id: 2,
      status: 'Submitted',
      stClass: 'submitted'
    },
    {
      id: 3,
      status: 'In-Review',
      stClass: 'in-review'
    },
    {
      id: 4,
      status: 'Pending-Edits',
      stClass: 'pending-edits'
    },
    {
      id: 5,
      status: 'Re-Submitted',
      stClass: 're-submitted'
    },
    {
      id: 10,
      status: 'Verified',
      stClass: 'verified'
    },
    {
      id: 11,
      status: 'Asset Created',
      stClass: 'asset-created'
    }
  ],
  VERIFIER: [
    { id: 0, status: 'All', stClass: 'all' },
    {
      id: 2,
      status: 'Submitted',
      stClass: 'submitted'
    },
    {
      id: 3,
      status: 'Claimed',
      stClass: 'claimed'
    },
    {
      id: 4,
      status: 'Awaiting Updates',
      stClass: 'awaiting-updates'
    },
    {
      id: 5,
      status: 'Re-Submitted',
      stClass: 're-submitted'
    },
    {
      id: 10,
      status: 'Verified',
      stClass: 'verified'
    },
    {
      id: 11,
      status: 'Asset Created',
      stClass: 'asset-created'
    }
  ]
};

export const API_PATH_URL = 'api/v1/create-reporting/{API_KEY}';
export const NWW_API_PATH_URL = 'api/v1/assets/create';
export const INACTIVITY_TIME = 10; // in minutes
export const INACTIVITY_EXPIRE = 30; //in seconds
export const IAssetPendingColsStatus = {
  isCancelledRejected: 'isCancelledRejected',
  directTransferOrAccept: 'directTransferOrAccept',
  canDoDirectTransferReject: 'canDoDirectTransferReject',
  postTransferCancelledRejected: 'postTransferCancelledRejected',
  canViewCommentReject: 'canViewCommentReject',
  postFinalize: 'postFinalize',
  postCancel: 'postCancel'
};

export const YEAR_SEARCH = 'y###';
export const FLLET_SEARCH = 'i##';
export const showInEnvironment = ['demo'];
export const RoleType = {
  VERIFIER: 2,
  PRJMANAGER: 2,
  SUPERADMIN: 0,
  ADMIN: 1,
  PRJVIEWER: 2,
  REALOWNER: 1,
  REALADMIN: 2
};

export const KeyEventCode = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  Enter: 'Enter',
  Tab: 'Tab',
  Escape: 'Escape',
  keyDown: 'keydown'
};

export const routesData = [
  '/transfer-history',
  '/retirement-history',
  '/pending-transfers',
  '/requests',
  '/posts/my-posts',
  '/posts/all-posted-assets',
  '/assets'
];
export const CERTIFICATE_ID = 'Certificate ID';
export const BASKET = 'Basket';
export const RoleKey = {
  RealmProjectManager: 'realm-pm',
  RealmOwner: 'realm-owner',
  RealmAdmin: 'realm-admin',
  RealmProjectVerifier: 'realm-pv',
  SuperAdmin: 'super-admin',
  RealmVerifier: 'realm-vr'
};
export const CONTENT_LENGTH = 20;
export const VERIFY = 'verify';
export const UNVERIFY = 'unverify';

export const FORMULA_VALUES_DECIMAL_FIX = 8;

export const DASS_PROCESS_FLOW_IDS = [4, 5, 6, 7, 8, 9, 10];
export const SOURCE_TARGET = '_blank';
export const GRAPH_ELEMENTS = [
  'williams-payload-production',
  'williams-payload-gathering-and-processing',
  'williams-payload-transmission'
];

export const isNewStructure = (methodologyId) => methodologyId > 15;

export const AssetsColsMappings = {
  is_transferred: 'asset_source',
  type: 'asset_name',
  asset_unit: 'asset_unit',
  total_posted_qty: 'total_posted_qty',
  total_pending_qty: 'total_pending_qty',
  total_transfered_qty: 'total_transfered_qty',
  total_available_qty: 'total_available_qty',
  total_retired_qty: 'total_retired_qty',
  source: 'source',
  created_date: 'created_date',
  updated_date: 'updated_date',
  cert_type: 'cert_type'
};
export const colGridClasses = {
  1: 'resize grid-cols-1',
  2: 'resize grid-cols-2',
  3: 'resize grid-cols-3',
  4: 'resize grid-cols-4',
  5: 'resize grid-cols-5',
  6: 'resize grid-cols-6',
  7: 'resize grid-cols-7',
  8: 'resize grid-cols-8',
  9: 'resize grid-cols-9',
  10: 'resize grid-cols-10',
  11: 'resize grid-cols-11',
  12: 'resize grid-cols-12',
  13: 'resize grid-cols-13',
  14: 'resize grid-cols-14',
  15: 'resize grid-cols-15'
};
