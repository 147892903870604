const Dashboard = 'Dashboard';
const Realm = 'Realm';
const User = 'User';
const Role = 'Role';
const Project = 'Project';
const Post = 'My-Posts';
const Asset = 'Assets-Inventory';
const OtherPost = 'Other-Posts';
const Request = 'Request';
const PendingTransfer = 'Pending-Transfers';
const RetiredAsset = 'Retirement';
const Transfer = 'Transfers';
const Profile = 'Profile';

export {
  Dashboard,
  Realm,
  User,
  Role,
  Project,
  Post,
  Asset,
  OtherPost,
  Request,
  PendingTransfer,
  RetiredAsset,
  Transfer,
  Profile
};
