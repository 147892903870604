export default (objArray, key) => {
  return objArray?.sort((a, b) => {
    let fa = a[key].toLowerCase(),
      fb = b[key].toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};
