import * as Auth from '~/static/urls/auth';
import useClearFetchStore from '@/stores/useClearFetchStore';
import useAuthStore from '@/stores/useAuthStore';

export default async () => {
  const clearObj = useClearFetchStore();
  const authObj = useAuthStore();
  await clearObj.clearGet(Auth.AUTHENTICATION_VENDOR);

  if (clearObj?.storeData?.data) {
    authObj.setAuthTypes(clearObj?.storeData?.data);
  }
};
