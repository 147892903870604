export const API_GET_URL = '/v1/users';
export const API_GET_URL_BY_REALM = '/v1/user-list-by-realm/{realmId}';
export const API_GET_URL_RETIRED_BY_FILTER = 'user-list-by-realm-to-filter/{realmId}';
export const API_VIEW_URL = '/v1/users/{user}';
export const API_POST_URL = '/v1/users';
export const API_UPDATE_URL = '/v1/users/{user}';
export const API_DELETE_URL = '/v1/users/{user}';

// user update profile
export const API_CHANGE_PROFILE = '/v1/update-profile';
export const API_CHANGE_PASSWORD_URL = '/v1/update-password';

// user activation process
export const API_UPDATE_PROFILE = '/v1/reset-user-profile/{reset_token}';
export const API_GET_USER_DETAIL_BY_TOKEN = '/v1/user-detail-by-token/{reset_token}';

// resend activation
export const API_RESEND_ACTIVATION_LINK = '/v1/resend-user-token/{userId}';

// accept terms
export const API_ACCEPT_TERM = '/v1/accept-term';
