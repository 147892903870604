import mitt from 'mitt';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((_nuxtApp) => {
  const emitter = mitt();
  return {
    provide: {
      event: emitter.emit, // Will emit an event
      listen: emitter.on // Will register a listener for an event
    }
  };
});
