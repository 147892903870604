import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import persistedstate_IZQNbWnwRA from "/app/src/plugins/persistedstate.js";
import vite_timeago3_JA9ZRrkUvy from "/app/src/plugins/vite-timeago3.js";
import vite_svg_sprite_wJVN0joZhY from "/app/src/plugins/vite-svg-sprite.js";
import vue_pdf_embed_client_lEPmZWQnVG from "/app/src/plugins/vue-pdf-embed.client.js";
import vite_casl_CorRAgAzUR from "/app/src/plugins/vite-casl.js";
import mitt_vMCLKGkJSU from "/app/src/plugins/mitt.js";
import vue_crypto_0t2nA6AEt8 from "/app/src/plugins/vue-crypto.js";
import error_reporting_jfuMJ99grd from "/app/src/plugins/error-reporting.js";
import vue_advanced_cropper_5UAbxNNEyF from "/app/src/plugins/vue-advanced-cropper.js";
import vue3_toastify_cswk3Fv1sd from "/app/src/plugins/vue3-toastify.js";
import vue3_clipboard_14jnkCU44i from "/app/src/plugins/vue3-clipboard.js";
import tooltip_PufnQ2FTUF from "/app/src/plugins/tooltip.js";
import vue_mask_lNlQyEDjY4 from "/app/src/plugins/vue-mask.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  persistedstate_IZQNbWnwRA,
  vite_timeago3_JA9ZRrkUvy,
  vite_svg_sprite_wJVN0joZhY,
  vue_pdf_embed_client_lEPmZWQnVG,
  vite_casl_CorRAgAzUR,
  mitt_vMCLKGkJSU,
  vue_crypto_0t2nA6AEt8,
  error_reporting_jfuMJ99grd,
  vue_advanced_cropper_5UAbxNNEyF,
  vue3_toastify_cswk3Fv1sd,
  vue3_clipboard_14jnkCU44i,
  tooltip_PufnQ2FTUF,
  vue_mask_lNlQyEDjY4
]