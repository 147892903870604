import { default as about_45us4quh9sPYskMeta } from "/app/src/pages/about-us.vue?macro=true";
import { default as accept_45termsgwagLyZS3oMeta } from "/app/src/pages/accept-terms.vue?macro=true";
import { default as _91token_93Nyb2EPRkbYMeta } from "/app/src/pages/activate/[token].vue?macro=true";
import { default as adminKPGIlZeS47Meta } from "/app/src/pages/admin.vue?macro=true";
import { default as createbasketJ4GnzL1phGMeta } from "/app/src/pages/assets/createbasket.vue?macro=true";
import { default as indexsuR7E7oGYBMeta } from "/app/src/pages/assets/index.vue?macro=true";
import { default as post2f7ERy2arMMeta } from "/app/src/pages/assets/post.vue?macro=true";
import { default as retiretOcxIO0EoIMeta } from "/app/src/pages/assets/retire.vue?macro=true";
import { default as transferF7rl0EQpt1Meta } from "/app/src/pages/assets/transfer.vue?macro=true";
import { default as update_45basket_45_91basketId_93nPj8ybDVTtMeta } from "/app/src/pages/assets/update-basket-[basketId].vue?macro=true";
import { default as dashboardLd3YXvnTkBMeta } from "/app/src/pages/dashboard.vue?macro=true";
import { default as forgot_45passwordvHMplVNcGwMeta } from "/app/src/pages/forgot-password.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as loading0LEIuDX6KZMeta } from "/app/src/pages/loading.vue?macro=true";
import { default as indexsTxGzTIguDMeta } from "/app/src/pages/mapper/index.vue?macro=true";
import { default as pending_45transfers0QFvtiyq0CMeta } from "/app/src/pages/pending-transfers.vue?macro=true";
import { default as all_45posted_45assetsuTh26r9KA8Meta } from "/app/src/pages/posts/all-posted-assets.vue?macro=true";
import { default as my_45postspEFNQxyuOAMeta } from "/app/src/pages/posts/my-posts.vue?macro=true";
import { default as editqGTiTNmkzCMeta } from "/app/src/pages/profile/edit.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as _91projectId_93SruFcGk1xOMeta } from "/app/src/pages/projects/[projectId].vue?macro=true";
import { default as create_45project_45apiD3vthBCK14Meta } from "/app/src/pages/projects/create-project-api.vue?macro=true";
import { default as createAmnvkgzS0kMeta } from "/app/src/pages/projects/create.vue?macro=true";
import { default as _91projectId_936OkcWqmf0iMeta } from "/app/src/pages/projects/edit/[projectId].vue?macro=true";
import { default as indexCBid7U1lDFMeta } from "/app/src/pages/projects/index.vue?macro=true";
import { default as _91projectId_93uuDEZNzc2WMeta } from "/app/src/pages/projects/reportings/[projectId].vue?macro=true";
import { default as view_45_91reportingId_93WyA2EQDWsBMeta } from "/app/src/pages/projects/reportings/view-[reportingId].vue?macro=true";
import { default as _91projectId_93lQOyf7Z00PMeta } from "/app/src/pages/projects/verification/[projectId].vue?macro=true";
import { default as _91projectId_930cmNLAs0Y3Meta } from "/app/src/pages/projects/view/[projectId].vue?macro=true";
import { default as _91realmId_93KoHXbmhNs9Meta } from "/app/src/pages/realms/[realmId].vue?macro=true";
import { default as createkYBvghTsU9Meta } from "/app/src/pages/realms/create.vue?macro=true";
import { default as indexCvaUtypjjJMeta } from "/app/src/pages/realms/index.vue?macro=true";
import { default as requestsmGuHUGUXZcMeta } from "/app/src/pages/requests.vue?macro=true";
import { default as retirement_45historyK7I2JITdJ0Meta } from "/app/src/pages/retirement-history.vue?macro=true";
import { default as _91roleId_9330ppyhG9aEMeta } from "/app/src/pages/roles/[roleId].vue?macro=true";
import { default as _91roleId_93dG4zb9ccdvMeta } from "/app/src/pages/roles/assign-permission/[roleId].vue?macro=true";
import { default as createXLuhXWGZiFMeta } from "/app/src/pages/roles/create.vue?macro=true";
import { default as indexkJg5YrUO75Meta } from "/app/src/pages/roles/index.vue?macro=true";
import { default as settings4uvkikcWGMMeta } from "/app/src/pages/settings.vue?macro=true";
import { default as terms_45of_45useDLhNEDqteIMeta } from "/app/src/pages/terms-of-use.vue?macro=true";
import { default as transfer_45historygQQFWOH2ZAMeta } from "/app/src/pages/transfer-history.vue?macro=true";
import { default as user_45guideJgeGwj2OCkMeta } from "/app/src/pages/user-guide.vue?macro=true";
import { default as _91userId_93EzuoH4WY0KMeta } from "/app/src/pages/users/[userId].vue?macro=true";
import { default as create1OYTP8mgMeMeta } from "/app/src/pages/users/create.vue?macro=true";
import { default as indexjNRiaiFqzBMeta } from "/app/src/pages/users/index.vue?macro=true";
export default [
  {
    name: about_45us4quh9sPYskMeta?.name ?? "about-us",
    path: about_45us4quh9sPYskMeta?.path ?? "/about-us",
    meta: about_45us4quh9sPYskMeta || {},
    alias: about_45us4quh9sPYskMeta?.alias || [],
    redirect: about_45us4quh9sPYskMeta?.redirect,
    component: () => import("/app/src/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: accept_45termsgwagLyZS3oMeta?.name ?? "accept-terms",
    path: accept_45termsgwagLyZS3oMeta?.path ?? "/accept-terms",
    meta: accept_45termsgwagLyZS3oMeta || {},
    alias: accept_45termsgwagLyZS3oMeta?.alias || [],
    redirect: accept_45termsgwagLyZS3oMeta?.redirect,
    component: () => import("/app/src/pages/accept-terms.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Nyb2EPRkbYMeta?.name ?? "activate-token",
    path: _91token_93Nyb2EPRkbYMeta?.path ?? "/activate/:token()",
    meta: _91token_93Nyb2EPRkbYMeta || {},
    alias: _91token_93Nyb2EPRkbYMeta?.alias || [],
    redirect: _91token_93Nyb2EPRkbYMeta?.redirect,
    component: () => import("/app/src/pages/activate/[token].vue").then(m => m.default || m)
  },
  {
    name: adminKPGIlZeS47Meta?.name ?? "admin",
    path: adminKPGIlZeS47Meta?.path ?? "/admin",
    meta: adminKPGIlZeS47Meta || {},
    alias: adminKPGIlZeS47Meta?.alias || [],
    redirect: adminKPGIlZeS47Meta?.redirect,
    component: () => import("/app/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: createbasketJ4GnzL1phGMeta?.name ?? "assets-createbasket",
    path: createbasketJ4GnzL1phGMeta?.path ?? "/assets/createbasket",
    meta: createbasketJ4GnzL1phGMeta || {},
    alias: createbasketJ4GnzL1phGMeta?.alias || [],
    redirect: createbasketJ4GnzL1phGMeta?.redirect,
    component: () => import("/app/src/pages/assets/createbasket.vue").then(m => m.default || m)
  },
  {
    name: indexsuR7E7oGYBMeta?.name ?? "assets",
    path: indexsuR7E7oGYBMeta?.path ?? "/assets",
    meta: indexsuR7E7oGYBMeta || {},
    alias: indexsuR7E7oGYBMeta?.alias || [],
    redirect: indexsuR7E7oGYBMeta?.redirect,
    component: () => import("/app/src/pages/assets/index.vue").then(m => m.default || m)
  },
  {
    name: post2f7ERy2arMMeta?.name ?? "assets-post",
    path: post2f7ERy2arMMeta?.path ?? "/assets/post",
    meta: post2f7ERy2arMMeta || {},
    alias: post2f7ERy2arMMeta?.alias || [],
    redirect: post2f7ERy2arMMeta?.redirect,
    component: () => import("/app/src/pages/assets/post.vue").then(m => m.default || m)
  },
  {
    name: retiretOcxIO0EoIMeta?.name ?? "assets-retire",
    path: retiretOcxIO0EoIMeta?.path ?? "/assets/retire",
    meta: retiretOcxIO0EoIMeta || {},
    alias: retiretOcxIO0EoIMeta?.alias || [],
    redirect: retiretOcxIO0EoIMeta?.redirect,
    component: () => import("/app/src/pages/assets/retire.vue").then(m => m.default || m)
  },
  {
    name: transferF7rl0EQpt1Meta?.name ?? "assets-transfer",
    path: transferF7rl0EQpt1Meta?.path ?? "/assets/transfer",
    meta: transferF7rl0EQpt1Meta || {},
    alias: transferF7rl0EQpt1Meta?.alias || [],
    redirect: transferF7rl0EQpt1Meta?.redirect,
    component: () => import("/app/src/pages/assets/transfer.vue").then(m => m.default || m)
  },
  {
    name: update_45basket_45_91basketId_93nPj8ybDVTtMeta?.name ?? "assets-update-basket-basketId",
    path: update_45basket_45_91basketId_93nPj8ybDVTtMeta?.path ?? "/assets/update-basket-:basketId()",
    meta: update_45basket_45_91basketId_93nPj8ybDVTtMeta || {},
    alias: update_45basket_45_91basketId_93nPj8ybDVTtMeta?.alias || [],
    redirect: update_45basket_45_91basketId_93nPj8ybDVTtMeta?.redirect,
    component: () => import("/app/src/pages/assets/update-basket-[basketId].vue").then(m => m.default || m)
  },
  {
    name: dashboardLd3YXvnTkBMeta?.name ?? "dashboard",
    path: dashboardLd3YXvnTkBMeta?.path ?? "/dashboard",
    meta: dashboardLd3YXvnTkBMeta || {},
    alias: dashboardLd3YXvnTkBMeta?.alias || [],
    redirect: dashboardLd3YXvnTkBMeta?.redirect,
    component: () => import("/app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordvHMplVNcGwMeta?.name ?? "forgot-password",
    path: forgot_45passwordvHMplVNcGwMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordvHMplVNcGwMeta || {},
    alias: forgot_45passwordvHMplVNcGwMeta?.alias || [],
    redirect: forgot_45passwordvHMplVNcGwMeta?.redirect,
    component: () => import("/app/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loading0LEIuDX6KZMeta?.name ?? "loading",
    path: loading0LEIuDX6KZMeta?.path ?? "/loading",
    meta: loading0LEIuDX6KZMeta || {},
    alias: loading0LEIuDX6KZMeta?.alias || [],
    redirect: loading0LEIuDX6KZMeta?.redirect,
    component: () => import("/app/src/pages/loading.vue").then(m => m.default || m)
  },
  {
    name: indexsTxGzTIguDMeta?.name ?? "mapper",
    path: indexsTxGzTIguDMeta?.path ?? "/mapper",
    meta: indexsTxGzTIguDMeta || {},
    alias: indexsTxGzTIguDMeta?.alias || [],
    redirect: indexsTxGzTIguDMeta?.redirect,
    component: () => import("/app/src/pages/mapper/index.vue").then(m => m.default || m)
  },
  {
    name: pending_45transfers0QFvtiyq0CMeta?.name ?? "pending-transfers",
    path: pending_45transfers0QFvtiyq0CMeta?.path ?? "/pending-transfers",
    meta: pending_45transfers0QFvtiyq0CMeta || {},
    alias: pending_45transfers0QFvtiyq0CMeta?.alias || [],
    redirect: pending_45transfers0QFvtiyq0CMeta?.redirect,
    component: () => import("/app/src/pages/pending-transfers.vue").then(m => m.default || m)
  },
  {
    name: all_45posted_45assetsuTh26r9KA8Meta?.name ?? "posts-all-posted-assets",
    path: all_45posted_45assetsuTh26r9KA8Meta?.path ?? "/posts/all-posted-assets",
    meta: all_45posted_45assetsuTh26r9KA8Meta || {},
    alias: all_45posted_45assetsuTh26r9KA8Meta?.alias || [],
    redirect: all_45posted_45assetsuTh26r9KA8Meta?.redirect,
    component: () => import("/app/src/pages/posts/all-posted-assets.vue").then(m => m.default || m)
  },
  {
    name: my_45postspEFNQxyuOAMeta?.name ?? "posts-my-posts",
    path: my_45postspEFNQxyuOAMeta?.path ?? "/posts/my-posts",
    meta: my_45postspEFNQxyuOAMeta || {},
    alias: my_45postspEFNQxyuOAMeta?.alias || [],
    redirect: my_45postspEFNQxyuOAMeta?.redirect,
    component: () => import("/app/src/pages/posts/my-posts.vue").then(m => m.default || m)
  },
  {
    name: editqGTiTNmkzCMeta?.name ?? "profile-edit",
    path: editqGTiTNmkzCMeta?.path ?? "/profile/edit",
    meta: editqGTiTNmkzCMeta || {},
    alias: editqGTiTNmkzCMeta?.alias || [],
    redirect: editqGTiTNmkzCMeta?.redirect,
    component: () => import("/app/src/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSJNeMkG0qzMeta?.name ?? "profile",
    path: indexSJNeMkG0qzMeta?.path ?? "/profile",
    meta: indexSJNeMkG0qzMeta || {},
    alias: indexSJNeMkG0qzMeta?.alias || [],
    redirect: indexSJNeMkG0qzMeta?.redirect,
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91projectId_93SruFcGk1xOMeta?.name ?? "projects-projectId",
    path: _91projectId_93SruFcGk1xOMeta?.path ?? "/projects/:projectId()",
    meta: _91projectId_93SruFcGk1xOMeta || {},
    alias: _91projectId_93SruFcGk1xOMeta?.alias || [],
    redirect: _91projectId_93SruFcGk1xOMeta?.redirect,
    component: () => import("/app/src/pages/projects/[projectId].vue").then(m => m.default || m)
  },
  {
    name: create_45project_45apiD3vthBCK14Meta?.name ?? "projects-create-project-api",
    path: create_45project_45apiD3vthBCK14Meta?.path ?? "/projects/create-project-api",
    meta: create_45project_45apiD3vthBCK14Meta || {},
    alias: create_45project_45apiD3vthBCK14Meta?.alias || [],
    redirect: create_45project_45apiD3vthBCK14Meta?.redirect,
    component: () => import("/app/src/pages/projects/create-project-api.vue").then(m => m.default || m)
  },
  {
    name: createAmnvkgzS0kMeta?.name ?? "projects-create",
    path: createAmnvkgzS0kMeta?.path ?? "/projects/create",
    meta: createAmnvkgzS0kMeta || {},
    alias: createAmnvkgzS0kMeta?.alias || [],
    redirect: createAmnvkgzS0kMeta?.redirect,
    component: () => import("/app/src/pages/projects/create.vue").then(m => m.default || m)
  },
  {
    name: _91projectId_936OkcWqmf0iMeta?.name ?? "projects-edit-projectId",
    path: _91projectId_936OkcWqmf0iMeta?.path ?? "/projects/edit/:projectId()",
    meta: _91projectId_936OkcWqmf0iMeta || {},
    alias: _91projectId_936OkcWqmf0iMeta?.alias || [],
    redirect: _91projectId_936OkcWqmf0iMeta?.redirect,
    component: () => import("/app/src/pages/projects/edit/[projectId].vue").then(m => m.default || m)
  },
  {
    name: indexCBid7U1lDFMeta?.name ?? "projects",
    path: indexCBid7U1lDFMeta?.path ?? "/projects",
    meta: indexCBid7U1lDFMeta || {},
    alias: indexCBid7U1lDFMeta?.alias || [],
    redirect: indexCBid7U1lDFMeta?.redirect,
    component: () => import("/app/src/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91projectId_93uuDEZNzc2WMeta?.name ?? "projects-reportings-projectId",
    path: _91projectId_93uuDEZNzc2WMeta?.path ?? "/projects/reportings/:projectId()",
    meta: _91projectId_93uuDEZNzc2WMeta || {},
    alias: _91projectId_93uuDEZNzc2WMeta?.alias || [],
    redirect: _91projectId_93uuDEZNzc2WMeta?.redirect,
    component: () => import("/app/src/pages/projects/reportings/[projectId].vue").then(m => m.default || m)
  },
  {
    name: view_45_91reportingId_93WyA2EQDWsBMeta?.name ?? "projects-reportings-view-reportingId",
    path: view_45_91reportingId_93WyA2EQDWsBMeta?.path ?? "/projects/reportings/view-:reportingId()",
    meta: view_45_91reportingId_93WyA2EQDWsBMeta || {},
    alias: view_45_91reportingId_93WyA2EQDWsBMeta?.alias || [],
    redirect: view_45_91reportingId_93WyA2EQDWsBMeta?.redirect,
    component: () => import("/app/src/pages/projects/reportings/view-[reportingId].vue").then(m => m.default || m)
  },
  {
    name: _91projectId_93lQOyf7Z00PMeta?.name ?? "projects-verification-projectId",
    path: _91projectId_93lQOyf7Z00PMeta?.path ?? "/projects/verification/:projectId()",
    meta: _91projectId_93lQOyf7Z00PMeta || {},
    alias: _91projectId_93lQOyf7Z00PMeta?.alias || [],
    redirect: _91projectId_93lQOyf7Z00PMeta?.redirect,
    component: () => import("/app/src/pages/projects/verification/[projectId].vue").then(m => m.default || m)
  },
  {
    name: _91projectId_930cmNLAs0Y3Meta?.name ?? "projects-view-projectId",
    path: _91projectId_930cmNLAs0Y3Meta?.path ?? "/projects/view/:projectId()",
    meta: _91projectId_930cmNLAs0Y3Meta || {},
    alias: _91projectId_930cmNLAs0Y3Meta?.alias || [],
    redirect: _91projectId_930cmNLAs0Y3Meta?.redirect,
    component: () => import("/app/src/pages/projects/view/[projectId].vue").then(m => m.default || m)
  },
  {
    name: _91realmId_93KoHXbmhNs9Meta?.name ?? "realms-realmId",
    path: _91realmId_93KoHXbmhNs9Meta?.path ?? "/realms/:realmId()",
    meta: _91realmId_93KoHXbmhNs9Meta || {},
    alias: _91realmId_93KoHXbmhNs9Meta?.alias || [],
    redirect: _91realmId_93KoHXbmhNs9Meta?.redirect,
    component: () => import("/app/src/pages/realms/[realmId].vue").then(m => m.default || m)
  },
  {
    name: createkYBvghTsU9Meta?.name ?? "realms-create",
    path: createkYBvghTsU9Meta?.path ?? "/realms/create",
    meta: createkYBvghTsU9Meta || {},
    alias: createkYBvghTsU9Meta?.alias || [],
    redirect: createkYBvghTsU9Meta?.redirect,
    component: () => import("/app/src/pages/realms/create.vue").then(m => m.default || m)
  },
  {
    name: indexCvaUtypjjJMeta?.name ?? "realms",
    path: indexCvaUtypjjJMeta?.path ?? "/realms",
    meta: indexCvaUtypjjJMeta || {},
    alias: indexCvaUtypjjJMeta?.alias || [],
    redirect: indexCvaUtypjjJMeta?.redirect,
    component: () => import("/app/src/pages/realms/index.vue").then(m => m.default || m)
  },
  {
    name: requestsmGuHUGUXZcMeta?.name ?? "requests",
    path: requestsmGuHUGUXZcMeta?.path ?? "/requests",
    meta: requestsmGuHUGUXZcMeta || {},
    alias: requestsmGuHUGUXZcMeta?.alias || [],
    redirect: requestsmGuHUGUXZcMeta?.redirect,
    component: () => import("/app/src/pages/requests.vue").then(m => m.default || m)
  },
  {
    name: retirement_45historyK7I2JITdJ0Meta?.name ?? "retirement-history",
    path: retirement_45historyK7I2JITdJ0Meta?.path ?? "/retirement-history",
    meta: retirement_45historyK7I2JITdJ0Meta || {},
    alias: retirement_45historyK7I2JITdJ0Meta?.alias || [],
    redirect: retirement_45historyK7I2JITdJ0Meta?.redirect,
    component: () => import("/app/src/pages/retirement-history.vue").then(m => m.default || m)
  },
  {
    name: _91roleId_9330ppyhG9aEMeta?.name ?? "roles-roleId",
    path: _91roleId_9330ppyhG9aEMeta?.path ?? "/roles/:roleId()",
    meta: _91roleId_9330ppyhG9aEMeta || {},
    alias: _91roleId_9330ppyhG9aEMeta?.alias || [],
    redirect: _91roleId_9330ppyhG9aEMeta?.redirect,
    component: () => import("/app/src/pages/roles/[roleId].vue").then(m => m.default || m)
  },
  {
    name: _91roleId_93dG4zb9ccdvMeta?.name ?? "roles-assign-permission-roleId",
    path: _91roleId_93dG4zb9ccdvMeta?.path ?? "/roles/assign-permission/:roleId()",
    meta: _91roleId_93dG4zb9ccdvMeta || {},
    alias: _91roleId_93dG4zb9ccdvMeta?.alias || [],
    redirect: _91roleId_93dG4zb9ccdvMeta?.redirect,
    component: () => import("/app/src/pages/roles/assign-permission/[roleId].vue").then(m => m.default || m)
  },
  {
    name: createXLuhXWGZiFMeta?.name ?? "roles-create",
    path: createXLuhXWGZiFMeta?.path ?? "/roles/create",
    meta: createXLuhXWGZiFMeta || {},
    alias: createXLuhXWGZiFMeta?.alias || [],
    redirect: createXLuhXWGZiFMeta?.redirect,
    component: () => import("/app/src/pages/roles/create.vue").then(m => m.default || m)
  },
  {
    name: indexkJg5YrUO75Meta?.name ?? "roles",
    path: indexkJg5YrUO75Meta?.path ?? "/roles",
    meta: indexkJg5YrUO75Meta || {},
    alias: indexkJg5YrUO75Meta?.alias || [],
    redirect: indexkJg5YrUO75Meta?.redirect,
    component: () => import("/app/src/pages/roles/index.vue").then(m => m.default || m)
  },
  {
    name: settings4uvkikcWGMMeta?.name ?? "settings",
    path: settings4uvkikcWGMMeta?.path ?? "/settings",
    meta: settings4uvkikcWGMMeta || {},
    alias: settings4uvkikcWGMMeta?.alias || [],
    redirect: settings4uvkikcWGMMeta?.redirect,
    component: () => import("/app/src/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useDLhNEDqteIMeta?.name ?? "terms-of-use",
    path: terms_45of_45useDLhNEDqteIMeta?.path ?? "/terms-of-use",
    meta: terms_45of_45useDLhNEDqteIMeta || {},
    alias: terms_45of_45useDLhNEDqteIMeta?.alias || [],
    redirect: terms_45of_45useDLhNEDqteIMeta?.redirect,
    component: () => import("/app/src/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: transfer_45historygQQFWOH2ZAMeta?.name ?? "transfer-history",
    path: transfer_45historygQQFWOH2ZAMeta?.path ?? "/transfer-history",
    meta: transfer_45historygQQFWOH2ZAMeta || {},
    alias: transfer_45historygQQFWOH2ZAMeta?.alias || [],
    redirect: transfer_45historygQQFWOH2ZAMeta?.redirect,
    component: () => import("/app/src/pages/transfer-history.vue").then(m => m.default || m)
  },
  {
    name: user_45guideJgeGwj2OCkMeta?.name ?? "user-guide",
    path: user_45guideJgeGwj2OCkMeta?.path ?? "/user-guide",
    meta: user_45guideJgeGwj2OCkMeta || {},
    alias: user_45guideJgeGwj2OCkMeta?.alias || [],
    redirect: user_45guideJgeGwj2OCkMeta?.redirect,
    component: () => import("/app/src/pages/user-guide.vue").then(m => m.default || m)
  },
  {
    name: _91userId_93EzuoH4WY0KMeta?.name ?? "users-userId",
    path: _91userId_93EzuoH4WY0KMeta?.path ?? "/users/:userId()",
    meta: _91userId_93EzuoH4WY0KMeta || {},
    alias: _91userId_93EzuoH4WY0KMeta?.alias || [],
    redirect: _91userId_93EzuoH4WY0KMeta?.redirect,
    component: () => import("/app/src/pages/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: create1OYTP8mgMeMeta?.name ?? "users-create",
    path: create1OYTP8mgMeMeta?.path ?? "/users/create",
    meta: create1OYTP8mgMeMeta || {},
    alias: create1OYTP8mgMeMeta?.alias || [],
    redirect: create1OYTP8mgMeMeta?.redirect,
    component: () => import("/app/src/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexjNRiaiFqzBMeta?.name ?? "users",
    path: indexjNRiaiFqzBMeta?.path ?? "/users",
    meta: indexjNRiaiFqzBMeta || {},
    alias: indexjNRiaiFqzBMeta?.alias || [],
    redirect: indexjNRiaiFqzBMeta?.redirect,
    component: () => import("/app/src/pages/users/index.vue").then(m => m.default || m)
  }
]