import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(FloatingVue, {
    themes: {
      'info-tooltip': {
        distance: 24,
        delay: { show: 500, hide: 0 }
      }
    }
  });
});
