import { extractString, usFormatDate,handleNumber } from '@/utils/config';
import { dtColumnArr, numColumnArr } from '@/models/AssetInventory';
import useModifyCol from '@/composables/useModifyCol';
import { BASKET } from '@/utils/constants.js';

const getAssetSource = (source, isTransferred) => {
  let assetSource = 'Basket';
  if (source === 'P') {
    assetSource = 'Project';
  }
  if (isTransferred) {
    assetSource = 'Transferred';
  }
  return assetSource;
};

function handleColumnValue(col, colValue) {
  if (dtColumnArr.includes(col)) {
    return usFormatDate(colValue);
  } else if (numColumnArr.includes(col) && colValue !== '') {
    if (typeof colValue === 'string') {
      colValue = colValue.replace(/,/g, '');
    }
    return handleNumber(colValue);
  }
  return colValue;
}

function handleColumn(col, row, colValue, isAsset) {
  if (col === 'source') {
    return useModifyCol(row, col, colValue, 'process_flow_id', 'id', isAsset);
  } else if (col === 'type') {
    if (colValue === 'B') {
      row.asset_icon = 'basket-circle';
      return {
        title: colValue,
        tooltip: true,
        tooltipTitle: BASKET
      };
    } else {
      return {
        title: colValue,
        tooltip: true,
        tooltipTitle: row['asset_name']
      };
    }
  } else if (col === 'methodology_sort_name') {
    return {
      title: row['methodology_sort_name'] ? row['methodology_sort_name'] : '-'
    };
  } else if (col === 'vintage') {
    return {
      title: extractString(colValue),
      tooltip: true,
      tooltipTitle: colValue
    };
  } else if (col === 'is_transferred') {
    return {
      title: row['asset_source'] ? row['asset_source'] : getAssetSource(row['type'], row['is_transferred'])
    };
  } else {
    return {
      title: colValue
    };
  }
}

export default (row, isAsset = true) => {
  for (let col in row) {
    if (typeof row[col] !== 'object' && col !== 'isActive') {
      let colValue = handleColumnValue(col, row[col]);
      row[col] = handleColumn(col, row, colValue, isAsset);
    } else if ([null, undefined, ''].includes(row[col])) {
      row[col] = {
        title: '-'
      };
    }
  }
  return row;
};
