import { computed, ref, watch } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import * as Auth from '~/static/urls/auth.js';
import { API_ACCEPT_TERM } from '~/static/urls/user.js';
import useAssetStore from '@/stores/useAssetStore';
import { assetFilterObj } from '@/utils/config';
import useClearFetchStore from '@/stores/useClearFetchStore';
import useGeneralStore from '@/stores/useGeneralStore';
import { ability } from '@/services/ability';
import { navigateTo, useGetAuthenticationTypes, useNuxtApp, useRouter } from '#imports';
import { RoleKey } from '@/utils/constants';
import { useFetch, useRuntimeConfig } from '#app';
import getLogOutChannel from '~/services/getLogOutChannel';
const useAuthStore = defineStore(
  'auth',
  () => {
    // state
    const userData = ref(null);
    const logoutUrl = ref('/');
    const authLogoutURL = ref('');
    const authTypes = ref([]);
    const selectedAuthType = ref(2);

    const clearFetch = useClearFetchStore();
    const generalObj = useGeneralStore();
    const { getStatus } = storeToRefs(clearFetch);
    const assetObj = useAssetStore();

    const router = useRouter();

    // getters
    const getUser = computed(() => {
      if (!userData.value) {
        return false;
      }
      return userData.value?.User;
    });

    const getAuthRealm = computed(() => {
      if (!userData.value) {
        return false;
      }
      return userData.value?.Realm;
    });

    const getIsAuthenticated = computed(() => clearFetch.getAccessToken);

    const getPermissions = computed(() => userData.value?.permissions);

    // actions
    const userDetails = async () => {
      logoutUrl.value = '/';
      const { data } = await clearFetch.clearGet(Auth.API_USER_DETAIL, true);
      if (data.value.data) {
        userData.value = data.value.data;
        if (getUser.value?.role_type === 0) {
          logoutUrl.value = '/admin';
        }
        const permissions = data.value.data?.permissions || [];
        ability.update(permissions);
      }
      return true;
    };
    const isSuperAdmin = computed(() => {
      if (!getUser.value) {
        return false;
      }
      return getUser.value.role_type === 0;
    });

    const isRealmAdmin = computed(() => {
      if (!getUser.value) {
        return false;
      }
      return getUser.value.role_key === 'realm-owner';
    });

    const isAdmin = computed(() => {
      if (!getUser.value) {
        return false;
      }
      return isRealmAdmin.value || getUser.value.role_key === 'realm-admin';
    });

    const isProjectManager = computed(() => {
      if (!getUser.value) {
        return false;
      }
      return getUser.value.role_key === 'realm-pm';
    });

    const afterLogout = async () => {
      resetLoginData();
      assetObj.setAssetFilter(assetFilterObj());
      await router.replace({ path: logoutUrl.value });
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, '', window.location.href);
      };
      window.location.reload();
    };

    const logout = async () => {
      clearFetch.setApiNotification(false);
      const runtimeConfig = useRuntimeConfig();
      const apiBase = runtimeConfig.public.apiBase;
      const clearFetchStore = JSON.parse(localStorage.getItem('clearFetch'));
      const headers = {};
      if (clearFetchStore?.loginData?.access_token) {
        headers.Authorization = `Bearer ${clearFetchStore?.loginData?.access_token}`;
      }
      await useFetch(() => `${Auth.API_AUTH_LOGOUT}`, {
        method: 'post',
        baseURL: apiBase,
        headers
      });
      const channel = getLogOutChannel();
      channel.postMessage({ isLogOut: true });

      await afterLogout();
    };

    const handleRedirect = async () => {
      if (userData.value) {
        if (!userData.value?.User?.accept_term) {
          await navigateTo('/accept-terms');
          return false;
        }
        if (isSuperAdmin.value) {
          navigateTo('/dashboard');
        }

        if (useNuxtApp().$ability.can('list', 'Dashboard')) {
          navigateTo('/dashboard');
        } else if (useNuxtApp().$ability.can('list', 'Project')) {
          navigateTo('/projects');
        } else {
          navigateTo('/profile');
        }
      } else {
        navigateTo('/');
      }
    };

    const shouldNavigateToProject = computed(() => {
      return [RoleKey.RealmProjectManager, RoleKey.RealmVerifier, RoleKey.RealmProjectVerifier].includes(
        getUser.value.role_key
      );
    });
    const signIn = async (signInData) => {
      clearFetch.setApiNotification(false);
      await clearFetch.clearPost(Auth.API_LOGIN, signInData);

      if (clearFetch.error) {
        if (process.client) {
          useNuxtApp().$toast.error('Invalid Credentials!!');
        }
      }

      if (clearFetch.storeData?.data) {
        clearFetch.setLoginData(clearFetch.storeData.data);
        await userDetails();
        if (isSuperAdmin.value) {
          await useGetAuthenticationTypes();
        }

        await handleRedirect();
        clearFetch.setApiNotification(true);
      }
    };

    const resetLoginData = () => {
      userData.value = null;
      clearFetch.setLoginData(null);
      ability.update([]);
      generalObj.setRealmId(0);
    };

    const fullName = computed(() => getUser.value && `${getUser.value.first_name} ${getUser.value.last_name}`);

    const getErrorObj = computed(() => clearFetch.getError);

    const getError = computed(() => clearFetch.error);

    const acceptTerm = async (req) => {
      await clearFetch.clearPost(API_ACCEPT_TERM, req);
      if (clearFetch.storeData?.data) {
        userData.value.User.accept_term = true;
        await handleRedirect();
      }
    };
    /**
     *  Set the auth type
     * @param {*} value
     */
    const setSelectedAuthType = (value) => {
      selectedAuthType.value = value;
    };
    /**
     *  set auth types listing
     */
    const setAuthTypes = (list) => {
      authTypes.value = list;
    };

    const setAuthLogoutURL = (url) => {
      authLogoutURL.value = url;
    };

    watch(getStatus, (newValue, oldValue) => {
      if (+newValue === 401 && +oldValue !== 401) {
        afterLogout();
      }
    });

    return {
      selectedAuthType,
      signIn,
      logout,
      authLogoutURL,
      setSelectedAuthType,
      setAuthTypes,
      isRealmAdmin,
      isAdmin,
      logoutUrl,
      userData,
      authTypes,
      getUser,
      getAuthRealm,
      getPermissions,
      getIsAuthenticated,
      getErrorObj,
      getError,
      fullName,
      isSuperAdmin,
      isProjectManager,
      shouldNavigateToProject,
      handleRedirect,
      userDetails,
      acceptTerm,
      setAuthLogoutURL,
      resetLoginData
    };
  },
  {
    persist: true
  }
);

export default useAuthStore;
