import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

const useModal = defineStore(
  'modalStore',
  () => {
    // state
    const isOpen = ref(false);
    const modalProps = ref({});

    const isOpened = computed(() => isOpen.value);
    const getModalProps = computed(() => modalProps.value);

    const setIsOpen = (state) => {
      isOpen.value = state;
    };

    const setModalProps = (state) => {
      modalProps.value = state;
    };

    const handleModal = (basketId) => {
      const rowObj = {
        basketId,
        modelValue: isOpen
      };
      setModalProps(rowObj);
      setIsOpen(true);
    };

    return {
      //state
      isOpen,
      modalProps,

      //getters
      isOpened,
      getModalProps,

      //actions
      setIsOpen,
      setModalProps,
      handleModal
    };
  },
  {
    persist: false
  }
);

export default useModal;
