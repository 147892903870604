<template>
  <main id="notfound" class="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
    <div class="flex flex-col justify-center justify-between">
      <div v-if="error?.statusCode === 404" class="text-center">
        <div class="flex justify-center text-center mt-10">
          <img :alt="$t('page_not_found')" src="~@/assets/svg/404.svg" />
        </div>
        <h1 class="mt-4 leading-7 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {{ $t('page_not_found') }}
        </h1>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <NuxtLink
            class="flex rounded-md bg-indigo-600 mt-6 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            to="/"
          >
            <SvgSprite class="w-6 h-6" symbol="arrow-left" />
            <span class="ml-4">{{ $t('back_to_home') }}</span>
          </NuxtLink>
        </div>
      </div>
    </div>
  </main>
</template>
<script setup>
import { SvgSprite } from 'vue-svg-sprite';
import { useError } from '#app';

const error = useError();
</script>
