import { ref } from 'vue';
import { defineStore } from 'pinia';

const useGeneralStore = defineStore(
  'general',
  () => {
    const isMenuActive = ref(false);
    const realmId = ref(0);
    const visitTo = ref(null);
    const setMenu = (isActive) => {
      isMenuActive.value = isActive;
    };

    const setVisitTo = (visitingPath) => (visitTo.value = visitingPath);
    const setRealmId = (realm) => {
      realmId.value = realm;
    };

    return {
      isMenuActive,
      realmId,
      visitTo,

      setMenu,
      setRealmId,
      setVisitTo
    };
  },
  {
    persist: true
  }
);

export default useGeneralStore;
