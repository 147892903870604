import * as ModulePermission from '@/utils/modelNames';
import useAssetCol from '@/composables/useAssetCol';

const numColumnArr = [
  'total_available_qty',
  'total_basketed_qty',
  'total_credited_qty',
  'total_pending_qty',
  'total_posted_qty',
  'total_retired_qty',
  'total_transfered_qty'
];
const dtColumnArr = ['credited_date', 'updated_date', 'created_date'];

class AssetInventory {
  constructor(request) {
    return request?.map((req) => {
      req = this.fixInventory(req);
      if (req?.type?.title === 'P') {
        if (!Object.hasOwn(req, 'children')) {
          return { ...req, children: [] };
        }
        return req;
      }
      return req;
    });
  }

  static get modelName() {
    return ModulePermission.Asset;
  }

  fixInventory(row) {
    return useAssetCol(row);
  }
}

export { AssetInventory, numColumnArr, dtColumnArr };
