import { extractString } from '@/utils/config';
import { CONTENT_LENGTH, DASS_PROCESS_FLOW_IDS, SOURCE_TARGET } from '@/utils/constants';
import useModal from '@/stores/useModal';

export default (row, col, colValue, workflow = 'process_flow_id', id = 'id', isAsset = false) => {
  const modalObj = useModal();
  if (typeof row[col] !== 'object') {
    row[col] = {
      title: extractString(colValue),
      tooltip: colValue.length > CONTENT_LENGTH,
      tooltipTitle: colValue
    };
  }
  if (row.type.title === 'B') {
    row[col].formatValue = (value) => value;

    row[col].handleClick = (aRow) => {
      const basketId = row[id]?.title || aRow[id];
      modalObj.handleModal(basketId);
    };
  } else {
    if (isAsset) {
      const instances = row?.project_instances?.title?.split(',');
      if (DASS_PROCESS_FLOW_IDS.includes(instances?.length && row[workflow]?.title)) {
        row[col].toURL = `/projects/reportings/${instances[0]}`;
        row[col].target = SOURCE_TARGET;
      } else {
        row[col].toURL = `/projects/view/${row[id]?.title}`;
        row[col].target = SOURCE_TARGET;
      }
    } else {
      const processFlowID = row[workflow]?.title || row[workflow];
      if (DASS_PROCESS_FLOW_IDS.includes(processFlowID)) {
        const assetId = row[id]?.title || row[id];
        row[col].toURL = `/projects/reportings/view-${assetId}`;
        row[col].target = SOURCE_TARGET;
      } else {
        row[col].toURL = `/projects/view/${
          typeof row?.project_id === 'object' ? row?.project_id?.title : row?.project_id
        }`;
        row[col].target = SOURCE_TARGET;
      }
    }
    return row[col];
  }
  return row[col];
};
